<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{ $t("settings.settings") }}</h3>
            </div>
          </div>
          <div class="card-body">
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item"
                :class="[i < tabNavigation.length - 1 ? 'mb-2' : '']"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{
                      active:
                        isExactActive ||
                        ($route.name.includes(item.route) &&
                          item.route !== 'adminTenantsDetails')
                    }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <SettingsBasic v-if="$route.name === 'tenantSettings'" />
      </div>
    </div>
  </div>
</template>

<script>
import SettingsBasic from "@/components/Tenants/Settings/Details/SettingsBasic";
export default {
  components: { SettingsBasic },
  props: [],
  data() {
    return {
      tabIndex: 0,
      tabNavigation: [
        {
          route: "tenantSettings",
          label: "settings.baseSettings",
          icon: "fal fa-gear"
        }
      ]
    };
  },
  mounted() {},
  methods: {}
};
</script>
