<template>
  <div>
    <Details :key="componentKey"></Details>
  </div>
</template>

<script>
import Details from "@/components/Tenants/Settings/Details";
import { mapGetters } from "vuex";

export default {
  name: "TenantsSettingsIndex",
  components: {
    Details
  },
  data() {
    return {
      clientSelected: false,
      componentKey: 1
    };
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  watch: {
    selectedClient: function () {
      this.componentKey++;
    }
  }
};
</script>
