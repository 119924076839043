<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        {{ $t("settings.baseSettings") }}
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary"
          :disabled="isBusy"
          @click="saveSettings"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate />
    <div class="card-body">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">{{ $t("settings.archiving") }}</h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("settings.keepLogForDays") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <div class="input-group">
            <input
              v-model="$v.settings.keepLogForDays.value.$model"
              type="number"
              class="form-control"
              :class="[
                $v.settings.keepLogForDays.value.$error
                  ? 'is-invalid'
                  : 'is-valid'
              ]"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                {{
                  settings.keepLogForDays.value !== 1
                    ? $t("settings.days")
                    : $t("settings.day")
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("settings.zipLogArchives") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <span class="switch switch-sm">
            <label>
              <input v-model="settings.zipLogArchives.value" type="checkbox" />
              <span></span>
            </label>
          </span>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("settings.keepLogArchiveForMonths") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <div class="input-group">
            <input
              v-model="$v.settings.keepLogArchiveForMonths.value.$model"
              :disabled="!settings.zipLogArchives.value"
              type="number"
              class="form-control"
              :class="[
                $v.settings.keepLogArchiveForMonths.value.$error
                  ? 'is-invalid'
                  : 'is-valid'
              ]"
            />
            <div class="input-group-append">
              <div class="input-group-text">
                {{
                  settings.keepLogArchiveForMonths.value !== 1
                    ? $t("settings.months")
                    : $t("settings.month")
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-dashed my-5"></div>
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">{{ $t("settings.numberRanges") }}</h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("settings.processIterationNumberRange") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <div class="input-group">
            <input
              v-model="$v.settings.processIterationNumberRange.value.$model"
              type="number"
              class="form-control"
              :class="[
                $v.settings.processIterationNumberRange.value.$error
                  ? 'is-invalid'
                  : 'is-valid'
              ]"
            />
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("settings.processLogNumberRange") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <div class="input-group">
            <input
              v-model="$v.settings.processLogNumberRange.value.$model"
              type="number"
              class="form-control"
              :class="[
                $v.settings.processLogNumberRange.value.$error
                  ? 'is-invalid'
                  : 'is-valid'
              ]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Settings from "@/components/Tenants/Settings/settings";
import { required, minValue } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  components: {},
  props: [],
  data() {
    return {
      settings: {
        keepLogForDays: {},
        zipLogArchives: {},
        keepLogArchiveForMonths: {},
        processIterationNumberRange: {},
        processLogNumberRange: {}
      },
      isBusy: false
    };
  },
  validations: {
    settings: {
      keepLogForDays: {
        value: { required, minValue: minValue(0) }
      },
      keepLogArchiveForMonths: {
        value: { required, minValue: minValue(0) }
      },
      processIterationNumberRange: {
        value: { required, minValue: minValue(0) }
      },
      processLogNumberRange: {
        value: { required, minValue: minValue(0) }
      }
    }
  },
  mounted() {
    this.setBreadcrumbs();
    this.loadSettings();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.tenantSettings"),
          route: "/tenant/settings"
        },
        {
          title: this.$t("settings.baseSettings"),
          route: "/tenant/settings"
        }
      ]);
    },
    loadSettings() {
      this.isBusy = true;
      let params = {
        size: 99,
        page: 1
      };
      Settings.getAll(params)
        .then(response => {
          let settings = {};
          response.data.data.forEach(setting => {
            if (setting.type === "number") {
              setting.value = parseInt(setting.value);
            }
            settings[setting.name] = setting;
          });
          this.settings = settings;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    async saveSettings() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isBusy = true;
      let settings = Object.values(this.settings);
      for (let setting of settings) {
        if (setting.name === "loginSecurity") continue;
        if (setting.type === "number") setting.value = setting.value.toString();
        await this.updateSetting(setting.id, setting);
      }
      this.$toast.fire({
        title: this.$t("settings.settingsUpdated"),
        icon: "success"
      });
      this.loadSettings();
    },
    async updateSetting(id, setting) {
      return Settings.update(setting.id, setting)
        .then(() => {
          return true;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
