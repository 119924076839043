import ApiService from "@/core/services/api.service";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

class Settings {
  get(id) {
    return ApiService.get(url, "settings/" + id);
  }
  getAll(params) {
    var options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "settings" + options);
  }
  getByName(name, apiKey) {
    return ApiService.get(url, "settings/getByName/" + name,  this.formatOptionalHeaders(apiKey));
  }

  store(data) {
    return ApiService.post(url + "/settings", data);
  }
  update(id, data, apiKey) {
    return ApiService.put(url + "/settings/" + id, data, this.formatOptionalHeaders(apiKey));
  }
  delete(id) {
    return ApiService.delete(url + "/settings/" + id);
  }

  formatOptionalHeaders(apiKey) {
    return {
      headers: {
        "x-api-key": apiKey
      }
    };
  }
}
export default new Settings();
